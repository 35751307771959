import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import store_logo from "../../images/store-logo/brandbldr-logo-dark.png";
import Swal from "sweetalert2";
import axios from "axios";
const Unsubscribe = () => {
    const location = useLocation();
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const email = queryParams.get('email');
        setEmail(email);
    }, []);
    const [email, setEmail] = useState("");
    const handleUnsubscribe = async(e)=>{
        e.preventDefault();
        if(email !== "" && email !== undefined ){
            const response = await axios.get(
				`${process.env.REACT_APP_FRONTEND_URL}api/user/unsubscribe/${email}`,
			);
            if(response != ""){
                Swal.fire({
                    icon: response.data.status,
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 2000 // milliseconds
                });
                setTimeout(() => {
                    window.location.href = process.env.REACT_APP_URL
                }, 2000); // 2000 milliseconds (2 seconds)
            }else{
                Swal.fire({
                    icon: "error",
                    title: "Something went wrong, try again later!",
                    showConfirmButton: false,
                    timer: 2000 // milliseconds
                });
                setTimeout(() => {
                    window.location.href = process.env.REACT_APP_URL
                }, 2000); // 2000 milliseconds (2 seconds)
            }
        }else{
            Swal.fire({
                icon: "error",
                title: "Something went wrong, try again later!",
                showConfirmButton: false,
                timer: 2000 // milliseconds
            });
            setTimeout(() => {
                window.location.href = process.env.REACT_APP_URL
            }, 2000); // 2000 milliseconds (2 seconds)
        }
    }
    return (<>
        <section className="new-banner min-h-100">
			<div className="container">
				<div className="logo-section">
					<img
						src={store_logo}
						alt="site-logo"
						className="img-fluid"
					/>
				</div>
                <div className="center">
                    <h1 className="main-heading">
                        Are you really sure to <span className="colored">Unsubscribe</span> mails from Brandbldr.io?
                    </h1>
                    {/* <div className="notice-text">
                        <h2>No strings attached </h2>
                        <h5>No upselling a course.</h5>
                        <h5>Just pure free education.</h5>
                    </div> */}
                    {/* <div className="mail-box">
                        <input type="email" name="email" onChange={handleEmailChange} value={email} placeholder="Enter your email" />
                        <button className="btn" onClick={handleClick} disabled={isError ? true : false}>Access</button>
                    </div> */}
                    {/* <div className="note text-red">{errorMsg}</div> */}
                    {/* <p className="sub-tagline">
                        As a special bonus, I'm pre-loading your store with 20 trending products and high quality suppliers
                    </p> */}
                    
                    {/* <div className="partners-img">
                        <img src={Partner} alt="" className="img-fluid" />
                        <img src={Partner1} alt="" className="img-fluid" />
                    </div> */}
                    <div className="text-center">
                        <button onClick={handleUnsubscribe} className="btn-unsubscribe">Unsubscribe Now!</button>
                    </div>
                </div>
			</div>
		</section>
    </>);
};
export default Unsubscribe;