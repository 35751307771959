import React, { Component, useEffect } from "react";
import StorebuilderForm from "./views/frontend/StorebuilderForm";
import Index from "./views/frontend/Index";
// admin
import Login from "./views/Login";

import {
	Routes,
	Route,
	BrowserRouter,
	Navigate,
	useNavigate
} from "react-router-dom";
import "./App.css";
import "./component/Stepper/stepper.css";
import AdminDashboard from "./views/Admin/Dashboard/Dashboard";
import UserDashboard from "./views/User/Dashboard/Dashboard";
import Success from "./views/frontend/Success";
import Continue from './views/frontend/Continue';
import Unsubscribe from "./views/frontend/Unsubcribe";
class App extends Component {
	render() {
		return (
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Index />} />
					<Route path="/start-building-brand" element={<StorebuilderForm />} />
					<Route path="/thankyou" element={<Success />} />
					<Route path="/login" element={<Login />} />
					<Route path="/admin/*" element={<ProtectedRoute />} />
					<Route path="/user/*" element={<ProtectedRoute />} />
					<Route path="/continue" element={<Continue />} />
					<Route path="/unsubcribe" element={<Unsubscribe />} />
				</Routes>
			</BrowserRouter>
		);
	}
}
const ProtectedRoute = ({ element, ...rest }) => {
	const isAuthenticated = !!localStorage.getItem("token"); // Check if the user is authenticated
	const role = localStorage.getItem("role");
	const navigate = useNavigate();
	useEffect(() => {
		if (!isAuthenticated) {
			// Redirect to the login page if not authenticated
			console.log("Redirecting to login");
			// Optionally, you can also store the intended route and redirect to it after login
		} else {
			const lastLoginTime = localStorage.getItem("lastLoginTime");
			// Convert JWT expiration time to a Unix timestamp (in seconds)
			const jwtExpTimestamp = parseInt(lastLoginTime, 10);

			// Get the current Unix timestamp (in seconds)
			const currentTimestamp = Math.floor(Date.now() / 1000);
			// const fifteenMinutes = 15 * 60 * 1000; // 15 minutes in milliseconds
			if (lastLoginTime && currentTimestamp > jwtExpTimestamp) {
				// Perform logout when 15 minutes of inactivity is reached
				localStorage.removeItem("token");
				localStorage.removeItem("role");
				localStorage.removeItem("userName");
				localStorage.removeItem("lastLoginTime");
				navigate("/login");
			}
		}
	}, [isAuthenticated, navigate]);
	if (isAuthenticated && parseInt(role) === 5150) {
		return <AdminRoutes />;
	} else if (isAuthenticated && parseInt(role) === 2004) {
		return <UserRoutes />;
	} else {
		return <Navigate to="/login" />;
	}
	// return isAuthenticated ? <AdminRoutes /> : <Navigate to="/login" />;
};
const AdminRoutes = () => {
	return (
		<Routes path="/admin">
			<Route path="home" element={<AdminDashboard screen="home" />} />
			<Route
				path="user-management"
				element={<AdminDashboard screen="user-management" />}
			/>
			<Route
				index
				path="product-management"
				element={<AdminDashboard screen="product-management" />}
			/>
			<Route
				index
				path="account-management"
				element={<AdminDashboard screen="account-management" />}
			/>
			<Route
				index
				path="email-templates"
				element={<AdminDashboard screen="email-templates" />}
			/>
			{/* Add other admin routes as needed */}
		</Routes>
	);
};
const UserRoutes = () => {
	return (
		<Routes path="/user">
			<Route path="home" element={<UserDashboard screen="home" />} />
			<Route path="shopify" element={<UserDashboard screen="shopify_link" />} />
			<Route path="profile" element={<UserDashboard screen="profile" />} />
			{/* Add other user routes as needed */}
		</Routes>
	);
};
export default App;
